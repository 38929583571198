<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button-group.tab-group
        b-button.mr-2(
          variant="primary",
          :class="{ off: currentReviewStatus !== 0 }",
          @click="changeReviewType(0)"
        ) 全部
        b-button.mr-2(
          variant="primary",
          :class="{ off: currentReviewStatus !== 1 }",
          @click="changeReviewType(1)"
        ) 暫存中
        b-button.mr-2(
          variant="primary",
          :class="{ off: currentReviewStatus !== 2 }",
          @click="changeReviewType(2)"
        ) 待審查
        b-button.mr-2(
          variant="primary",
          :class="{ off: currentReviewStatus !== 3 }",
          @click="changeReviewType(3)"
        ) 待發布
        b-button(
          variant="primary",
          :class="{ off: currentReviewStatus !== 4 }",
          @click="changeReviewType(4)"
        ) 已發布
      router-link(to="/article/editor/create")
        b-button.addbtn.ml-1.width-lg(variant="primary", size="lg")
          i.ri-file-add-fill.mr-1
          span 新增稿件
    .col-lg-12
      //- 搜尋bar
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 篩選查詢
              i.fe-settings.ml-2
            a.collapse-turn.mr-2(
              @click="searchConsoleVisible = !searchConsoleVisible",
              href="javascript: void(0);"
            )
              span(v-if="searchConsoleVisible") 收起
                i.fe-chevron-up
              span(v-else) 展開
                i.fe-chevron-down
            b-button.ml-1.width-lg(variant="primary", @click="reloadArticleList") 查詢
            b-button.ml-1.width-lg(variant="secondary",@click="clearSearchField") 清除

        b-collapse#customaccordion-1(
          v-model="searchConsoleVisible",
          accordion="accordion",
          role="tabpanel"
        )
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label 稿件編號
                    br
                    input.form-control(
                      v-model="search.news_code",
                      type="text",
                      placeholder="請輸入稿件編號"
                    )
                .col-lg-4
                  .form-group.mb-3
                    label 新聞類別
                    br
                    .row
                      .col
                        multiselect(
                          v-model="currentMainType",
                          track-by="id",
                          label="name",
                          :options="$root.newsTypeList",
                          placeholder="選擇主分類",
                          select-label=""
                        )
                          template(slot="singleLabel", slot-scope="{ option }")
                            strong {{ option.name }}

                      .col-6(v-if="currentMainType.id")
                        multiselect(
                          v-model="currentSubType",
                          :options="currentMainType.sub_types",
                          track-by="id",
                          label="name",
                          placeholder="選擇次分類",
                          select-label=""
                        )
                .col-lg-4
                  .form-group.mb-3
                    label 來源
                    br
                    .row
                      b-button.btn-rounded.ml-1(
                        :variant="search.source_type === 0 ? 'primary' : 'outline-primary'",
                        @click="changeStatus(0)"
                      ) 全部
                      b-button.btn-rounded.ml-1(
                        :variant="search.source_type === 1 ? 'primary' : 'outline-primary'",
                        @click="changeStatus(1)"
                      ) 後台
                      b-button.btn-rounded.ml-1(
                        :variant="search.source_type === 2 ? 'primary' : 'outline-primary'",
                        @click="changeStatus(2)"
                      ) RSS
                      b-button.btn-rounded.ml-1(
                        :variant="search.source_type === 3 ? 'primary' : 'outline-primary'",
                        @click="changeStatus(3)"
                      ) 網友投稿
                      b-button.btn-rounded.ml-1(
                        :variant="search.source_type === 4 ? 'primary' : 'outline-primary'",
                        @click="changeStatus(4)"
                      ) 網友爆料
                      b-button.btn-rounded.ml-1(
                        :variant="search.source_type === 5 ? 'primary' : 'outline-primary'",
                        @click="changeStatus(5)"
                      ) AI寫稿
              .row
                .col-lg-4
                  .form-group.mb-3
                    label TAG
                    br
                    multiselect(
                      v-model="currentTag",
                      :options="tags",
                      select-label="",
                      :multiple="false"
                      label="name",
                      track-by="id",
                      placeholder="請選擇TAG",
                      @search-change="searchTagList"
                    )
                .col-lg-4
                  .form-group.mb-3
                    label 啟用狀態
                    br
                    multiselect(
                      v-model="search.status",
                      :options="statusList",
                      label="name",
                      track-by="id",
                      placeholder="請選擇啟用狀態",
                      select-label=""
                    )
                .col-lg-4
                  .form-group.mb-3
                    label 標題相關字
                    br
                    input.form-control(
                      v-model="search.keyword",
                      placeholder="請輸入標關字"
                    )
              .row
                .col-lg-4
                  .form-group.mb-3
                    label 中心類型
                    br
                    select.form-control(v-model="search.center_type")
                      option(value="") 全部
                      option(value="1") 即時中心
                      option(value="2") 要聞中心
                      option(value="3") 大國中心
                      option(value="4") 娛樂中心
                      option(value="5") 健康中心
                .col-lg-4
                  .form-group.mb-3
                    label 上稿人員
                    br
                    multiselect(
                      v-model="currentManager",
                      :options="managers",
                      placeholder="請選擇人員",
                      label="name",
                      track-by="id",
                      @search-change="searchManagerList",
                      select-label=""
                    )
                .col-lg-4
                  .form-group.mb-3
                    label 上稿時間
                    br
                    date-picker(
                      v-model="currentDateRange",
                      range,
                      append-to-body,
                      lang="en",
                      confirm,
                      format="YYYY-MM-DD",
                      placeholder="請選擇日期區間"
                    )
      .card
        .card-body
          .header-row.mb-3
            .header-title 稿件列表
            b-button.width-lg(
              variant="outline-secondary",
              @click="reloadArticleList"
            )
              i.fe-refresh-ccw
              span.ml-2 重新整理
          .row
            .col-12
              //- Table
              b-table(
                ref="articleListTable"
                responsive,
                :items="searchNewsList",
                :fields="fields",
                :per-page="perPage",
                :current-page="currentPage",
                :sort-desc.sync="sortDesc",
                :filter="filter",
                :filter-included-fields="filterOn",
                @filtered="onFiltered"
              )
                //- 稿件編號
                template(v-slot:cell(code)="row")
                  b-button.table-btn(
                    @click="$root.common.copyCuponCode(row.item.code)"
                  )
                    | {{ row.item.code }}

                template(v-slot:cell(source)="row")
                  span(v-if="row.item.source == '1'") 後台編輯
                  span(v-if="row.item.source == '2'") RSS導入
                  span(v-if="row.item.source == '3'") 網友投稿
                  span(v-if="row.item.source == '4'") 網友爆料
                  span(v-if="row.item.source == '5'") AI寫稿
                  .ai-mark-show(v-if="row.item.marks.indexOf('aiModelArticle') > -1")
                    span.badge.badge-dark AI生成

                template(v-slot:cell(rss_name)="row")
                  .text-center
                    span(v-if="row.item.rss_name") {{ row.item.rss_name }}
                    span(v-else) -

                template(v-slot:cell(main_type)="row")
                  .text-center
                    span(v-if="row.item.main_type.father") {{ row.item.main_type.father.name }}
                    span(v-if="row.item.main_type.id") {{ ' / ' + row.item.main_type.name }}
                    span(v-else) -

                //- 前台狀態
                template(v-slot:cell(is_online)="row")
                  b-button(
                    v-if="row.item.is_online",
                    variant="success",
                    size="sm"
                  ) 有公開版本
                  b-button(v-else, variant="secondary", size="sm") 未上架

                //- 審查狀態
                template(v-slot:cell(review_at)="row")
                  b-button(
                    v-if="row.item.review_at",
                    variant="success",
                    size="sm"
                  ) 已審查
                  b-button(v-else, variant="secondary", size="sm") 未審查

                //- 上稿人員
                template(v-slot:cell(editer)="row")
                  span(v-if="row.item.editer.name") {{ row.item.editer.name }}
                  span(v-else) -

                //- 審查人員
                template(v-slot:cell(reviewer)="row")
                  span(v-if="row.item.reviewer.name") {{ row.item.reviewer.name }}
                  span(v-else) -

                //- tags
                template(v-slot:cell(tags)="row")
                  span(v-if="!row.item.tags.length") -
                  b-button.btn-soft-secondary.mr-1(
                    variant="secondary",
                    size="sm",
                    pill,
                    :key="tag.id"
                    v-for="tag in row.item.tags"
                  )
                    | {{ tag.name }}

                //- 編輯
                template(v-slot:cell(edit)="row")
                  b-button.table-btn.mr-2(
                    @click="preView(row.item.id, row.item.review_at)"
                  )
                    span(v-if="row.item.review_at") 前台網址
                    span(v-else) 預覽網址
                  b-button.table-btn(
                    v-if="$root.common.confirmUserAccess('ARTICLE_EDIT_CONTENT')" 
                    @click="editArticle(row.item.id)"
                  )
                    | 編輯

          .row.mb-md-2
            .col-6
              //- #tickets-table_length.dataTables_length
                label.d-inline-flex.align-items-center
                  | 頁
                  b-form-select.ml-2.mr-2(
                    v-model="perPage",
                    size="sm",
                    :options="pageOptions"
                  )
                  | 筆
            .col-6
              .dataTables_paginate.paging_simple_numbers.float-right
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(
                    v-model="currentPage",
                    :total-rows="totalRows",
                    :per-page="perPage"
                    limit="1"
                  )
                  
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  name: "ArticleList",
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  watch: {
    '$root.socket_ready' (_status) {
      this.$root.socket.RegisterDashboardArticleStatusChangeEvent(
        this.processArticleStatusChange
      )
    },
    currentMainType (_currentMainType) {
      console.log('=> 變更搜尋主類別:', _currentMainType)
      if (_currentMainType.id) {
        this.search.main_type = _currentMainType.id
      }
    },
    currentSubType (_currentSubType) {
      if (_currentSubType.id) {
        this.search.sub_type = _currentSubType.id
      }
    },
    currentTag (_currentTag) {
      if (_currentTag.id) {
        this.search.tag = _currentTag.id
      }
    },
    currentManager (_currentManager) {
      if (_currentManager.id) {
        this.search.edit_uid = _currentManager.id
      }
    },
    currentDateRange (_currentDateRange) {
      if (_currentDateRange.length > 0) {
        this.search.edit_start_date = this.$root.common.getNowTime('date', _currentDateRange[0])
        console.log('=> 區間起始日:', this.search.edit_start_date)
        this.search.edit_end_date = this.$root.common.getNowTime('date', _currentDateRange[1])
        console.log('=> 區間結束日:', this.search.edit_end_date)
      }
    },
    currentReviewStatus (_currentReviewStatus) {
      this.search.review_status = _currentReviewStatus
    }
  },
  data () {
    return {
      interval: false,
      title: "新聞/文章編輯",
      currentReviewStatus: 0,
      currentMainType: { id: "" },
      currentSubType: { id: "" },
      currentManager: false,
      currentDateRange: [],
      currentTag: [],
      search: {
        page: 1,
        review_status: 0,
        news_code: '',
        main_type: '',
        sub_type: '',
        source_type: 0,
        tag: "",
        status: '',
        keyword: '',
        user_type: 'editor',
        user_id: '',
        date_type: 'create',
        date_range: [],
        center_type: ''
      },
      tags: [],
      managers: [],
      statusList: [
        { id: 0, name: "全部" },
        { id: 1, name: "啟用" },
        { id: 2, name: "未啟用" }
      ],
      userCenterList: [
        { id: 0, name: "全部" },
        { id: 1, name: "即時中心" },
        { id: 2, name: "要聞中心" },
        { id: 3, name: "大國中心" },
        { id: 4, name: "娛樂中心" },
        { id: 5, name: "健康中心" }
      ],
      items: [
        {
          text: "上稿管理",
          href: "/",
        },
        {
          text: "新聞/文章編輯",
          active: true,
        }
      ],
      articleList: [],
      searchConsoleVisible: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        { key: 'code', label: "稿件編號", sortable: false, class: 'width-auto center' },
        { key: 'source', label: "來源類型", sortable: false, class: 'width-auto center' },
        { key: 'rss_name', label: "來源組別", sortable: false, class: 'width-auto center' },
        { key: 'main_type', label: "分類", sortable: false, class: 'width-auto center' },
        { key: 'title', label: "標題", sortable: false, class: 'width-auto' },
        { key: 'is_online', label: "前台狀態", sortable: false, class: 'width-auto center' },
        { key: 'review_at', label: "審查狀態", sortable: false, class: 'width-auto center' },
        { key: 'editer_name', label: "作者名稱", sortable: false, class: 'width-auto center' },
        { key: 'editer', label: "編輯人員", sortable: false, class: 'width-auto center' },
        { key: 'post_at', label: "上稿時間", sortable: true, class: 'width-auto center' },
        { key: 'reviewer', label: "審查人員", sortable: false, class: 'width-auto center' },
        { key: 'review_at', label: "審查日期", sortable: true, class: 'width-auto center' },
        // { key: 'tags', label: "TAGS", sortable: false, class: 'width-auto center' },
        { key: 'edit', label: "操作", sortable: false, stickyColumn: true, class: 'width-auto center' },
      ]
    };
  },
  computed: {
  },
  mounted () {
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker
  },
  created () {
    if (this.$root.socket_ready) {
      this.$root.socket.RegisterDashboardArticleStatusChangeEvent(
        this.processArticleStatusChange
      )
    }
  },
  methods: {
    clearSearchField () {
      this.currentSubType = { id: '' }
      this.currentMainType = { id: '' }
      this.search.news_code = ''
      this.search.main_type = ''
      this.search.sub_type = ''
      this.search.source_type = 0
      this.search.tag = []
      this.search.status = ''
      this.search.keyword = ''
      this.search.user_type = 'editor'
      this.search.user_id = ''
      this.search.date_type = 'create'
      this.search.date_range = []
    },
    reloadArticleList () {
      this.$refs.articleListTable.refresh()
    },
    searchNewsList (_table, _callback) {
      var vm = this
      this.search.page = vm.currentPage
      this.$root.apis.getNewsListByEditor(this.search, function (_response) {
        let response = _response.body.data
        vm.totalRows = response.total
        vm.currentPage = response.page
        vm.perPage = response.per_page
        vm.articleList = response.news
        if (typeof _callback === 'function')
          _callback(response.news)
      })
    },
    processArticleStatusChange (_article) {
      console.log('=> 接收到稿件狀態變動通知:', _article);

      let serachAnser = false
      let articleId = _article.ArticleID
      this.articleList.forEach(function (_item) {
        if (_item.id === articleId) {
          serachAnser = true
        }
      })

      if (serachAnser) {
        this.reloadArticleList()
      }
    },
    // 搜尋符合的標籤
    searchTagList (_text) {
      let vm = this
      console.log("=> 標籤搜尋:", _text)
      this.$root.apis.getFastTagList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.tags = _response.body.data.items
      })
    },
    searchManagerList (_text) {
      let vm = this
      console.log("=> 管理員搜尋:", _text)
      this.$root.apis.getFastManagerList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.managers = _response.body.data.items
      })
    },
    /**
     * Search the table data with search input
     */
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeStatus (_type) {
      this.search.source_type = _type
    },
    changeReviewType (_status) {
      this.currentReviewStatus = _status
    },
    // 請求預覽文章
    preView (_articleId, _isReviewed = "") {
      console.log('=> 請求預覽新聞文章:', _articleId)

      if (!_isReviewed) {
        // 目前有待審查內容
        window.open(this.$root.site_uri + '/news/items/' + _articleId + "?mode=preview")
      }

      else {
        // 目前無待審查內容
        window.open(this.$root.site_uri + '/news/items/' + _articleId)
      }
    },
    // 請求編輯文章
    async editArticle (_articleId) {
      // 驗證是否可以編輯
      let vm = this
      await this.$root.apis.checkArticleCanEditStatus(_articleId,
        async function (_response) {

          let response = _response.body.data
          // 稿件可以編輯
          if (response.can_edit) {
            vm.$router.push({ name: 'ArticleEditor', params: { NewsHashId: _articleId } })
          }
          // 稿件不能編輯
          else {
            // 預設無主管權限時
            let accessText = "晚點再試"
            let doNotAnything = function () { }
            let accessAction = function () { }
            // 如果有主管權限時
            if (vm.$root.common.confirmUserAccess('ARTICLE_PUBLISH_CONTENT')) {
              accessText = "強制編輯(僅限主管)"
              accessAction = async function () {
                await vm.$root.apis.resetArticleCanEditStatus(_articleId, true)
                await vm.$root.apis.checkArticleCanEditStatus(_articleId)
                await vm.$router.push({ name: 'ArticleEditor', params: { NewsHashId: _articleId } })
              }
            }

            vm.$root.common.confirmAction(
              "稿件編輯中",
              "目前系統記錄顯示稿件正由用戶『" + response.edit_manager + "』編輯",
              accessText,
              "放棄編輯",
              accessAction,
              doNotAnything
            )
          }
        },
        function (_error) {
          vm.$root.common.showErrorNotify(_error.body.data)
        })
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
</style>
